import React from 'react'
import "./appInfo.css"
export const AppInfo = () => {
  return (
    <div className='app-info'>
        <div className="app-info__container">
            <h1 className="app-info_title">
                Développement de la version  
            </h1>
            <p className="app-info_sub-heading"> 2 d'Uniplot</p>
        </div>
    </div>
  )
}
