import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useContext, useState } from "react";
import { storage } from "../../../firebase/Config";
import { MyContext } from "../../../../context/MyContext";
import { Link, useNavigate } from "react-router-dom";
import db from "../../../appwrite/database";
import './feedImage.css'
const FeedImage = () => {
  const { text, setText } = useContext(MyContext);
  
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const image = e.target.files[0]
    if (image) {
      console.log(image);
      const storageRef = ref(storage, `images/${image.name}`);
      uploadBytes(storageRef, image).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setUrl(url);
          console.log(url);
          setText({ ...text, image: url });
          console.log(text);
        });
      });
    }
  };

  const handleUpload = async () => {
    // const storageRef = ref(storage, `images/${image.name}`);
    // uploadBytes(storageRef, image).then((snapshot) => {
    //   getDownloadURL(snapshot.ref).then((url) => {
    //     setUrl(url);
    //     console.log(url)

    //     console.log(text);
    //   });
    // });

    try {
      const response = await db.Bug.create(text);
      setText({ ...text, image: url });
      console.log("this is the ");
      console.log(text);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="image-container">
      <div className="feed-image">
        <span className="image-title">Choisir une image</span>
        <input type="file" onChange={handleChange} />
      </div>
      <div className="feed-button">
        <Link to="/">
          <button className="cancel"> Cancel</button>
        </Link>
        <button className="add" onClick={handleUpload} Disable={true}>
          Add feedback
        </button>
      </div>
    </div>
  );
};

export default FeedImage;
