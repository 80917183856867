export const Suggestion = [
    {
      id:"6655c9085752f177cbaaaec6",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 20",
      category : "Bug" ,
      numberLikes : 23,
      numberComment : "3",
    },
    {
      id:"2",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 45",
      category : "UI" ,
      numberLikes : "27",
      numberComment : 5,
    },
    {
      id:"3",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 20 ",
      category : "Feauture" ,
      numberLikes : 26,
      numberComment : 45,
    },
    {
      id:"4",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 4578",
      category : "UX" ,
      numberLikes : 27,
      numberComment : 17,
    },
    {
      id:"5",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 89",
      category : "Enhacement" ,
      numberLikes : 12,
      numberComment : 10,
    },
    {
      id:"6",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 36",
      category : "Feauture" ,
      numberLikes : 13,
      numberComment : 230,
    },
    {
      id:"7",
      title: "assets/person/1.jpeg",
      username: "Safak Kocaoglu",
      subtitle : "This is the subtitle of 785",
      category : "Enhacement" ,
      numberLikes : 17,
      numberComment : 150,
    },
]

export const comment = [
  {
    idSuggestion : 1,
    comment : [
      {
        id : 1,
        content : "Cette partie n'est pas encore développé, il peut être utilisé pour commenter des pistes d'améliorations de la deuxième versions de l'outil de dépouillement ",
        user : {
          image : "photo-avatar-profil.png",
          name : "Youness AYYOUBI",
        }
      },
      
      
      
    ] 


  }

  
]