import React from "react";
import "./filterData.css";
export const FilterData = () => {
    const handleClick = (event) => {
        console.log(event.target.style)
        event.target.style.backgroundColor  = '#1127a3'
    }
  return (
    <div className="filter-data">
      <div className="filter-data_container">
        <span className="filter-title"> Filtrer les données </span>
        <div className="filter-data_options">
            <span className="filter-item" onClick={handleClick}> All </span>
            <span className="filter-item" onClick={handleClick}> Bug </span>
            <span className="filter-item" onClick={handleClick}> Enhacement  </span>

        </div>
      </div>
    </div>
  );
};
