// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage"; 
// const firebaseConfig = {
//   apiKey: "AIzaSyCk2xm6J1lv-O63bLFunHgYh97Fvw3ixPM",
//   authDomain: "feedback-e557d.firebaseapp.com",
//   projectId: "feedback-e557d",
//   storageBucket: "feedback-e557d.appspot.com",
//   messagingSenderId: "988399978144",
//   appId: "1:988399978144:web:42f8b3023a5ea379e8abc5",
//   measurementId: "G-SS7J3384F6"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const imageDb = getStorage(app)

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCk2xm6J1lv-O63bLFunHgYh97Fvw3ixPM",
    authDomain: "feedback-e557d.firebaseapp.com",
    projectId: "feedback-e557d",
    storageBucket: "feedback-e557d.appspot.com",
    messagingSenderId: "988399978144",
    appId: "1:988399978144:web:42f8b3023a5ea379e8abc5",
    measurementId: "G-SS7J3384F6"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export {storage};